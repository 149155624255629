<template>
  <div class="premium-badge badge">
    <Icon :size="10" type="vip-crown"/>

    {{ $t('badges.premium') }}
  </div>
</template>

<script>
import Icon from '@/components/Icon'

export default {
  name: 'Premium',
  components: {
    Icon
  }
}
</script>
