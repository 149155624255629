<template>
  <subview class="newcompetition-appearence-judgements">
    <view-header>
      <custom-header :router-back="$goBack">
        <template v-slot:default>
          <div>
            <Icon :size="24" type="arrow-left"/>

            {{ $t('pages.newcompetition.appearance.back') }}
          </div>
        </template>
      </custom-header>
    </view-header>

    <view-content>
      <h4> {{ title }} </h4>

      <Judgement v-for="judgement in judgements" :key="judgement.id" :judgement="judgement"/>
    </view-content>
  </subview>
</template>

<script>
import Icon from '@/components/Icon'
import Judgement from "@/components/NewCompetition/Appearance/Judgement"
import newcompetition from '@/mixins/newcompetition'

export default {
  name: 'Judgements',
  mixins: [
    newcompetition
  ],
  components: {
    Icon,
    Judgement
  },
  computed: {
    title () {
      return this.selectedSport?.appearance.judgements.title || this.$t('pages.newcompetition.appearance.judgements.title')
    },
    judgements () {
      return this.selectedSport?.appearance.judgements.variations || []
    }
  }
}
</script>
