<template>
  <div class="judgement-component" :class="classes" @click="select" ref="element">
    <span class="name">
      {{ name }}
    </span>

    <p v-if="description" class="description">
      {{ description }}
    </p>

    <Premium v-if="premium"/>
    <ComingSoon v-if="comingsoon"/>
  </div>
</template>

<script>
import Premium from '@/components/Badges/Premium'
import ComingSoon from '@/components/Badges/ComingSoon'
import newcompetition from '@/mixins/newcompetition'

export default {
  name: 'Judgement',
  mixins: [
    newcompetition
  ],
  components: {
    Premium,
    ComingSoon
  },
  props: {
    judgement: {
      type: Object,
      required: true
    }
  },
  computed: {
    classes () {
      const { comingsoon, premium, selected } = this

      return { comingsoon, premium, selected }
    },
    id () {
      return this.judgement.id
    },
    name () {
      return this.judgement.name
    },
    selected () {
      return this.selectedAppearance.judgements?.id === this.id
    },
    description () {
      return this.judgement.description
    },
    premium () {
      return this.judgement.status === 'Premium'
    },
    comingsoon () {
      return this.judgement.status === 'Coming soon'
    }
  },
  mounted () {
    if (this.selected) {
      this.scroll() 
    }
  },
  methods: {
    select () {
      this.updateAppearance('judgements', this.judgement)

      this.scroll()
    },
    scroll () {
      this.$refs.element.scrollIntoView({ behavior: 'smooth', inline: 'center' })
    }
  }
}
</script>
